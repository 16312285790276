import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import SectionHeading from '../components/SectionHeading'
import { Section, InnerMedium, InnerSmall } from '../components/Sections'
import Form from '../components/pageSections/Form'
import color from '../constants/colors'

const InnerStyledGray = styled(InnerSmall)`
  background-color: ${color.lightBlue};
  padding: 30px;
`

const FormWrapper = styled.div`
  background-color: ${color.silverBg};
  margin-bottom: 50px;
  padding: 50px 15px 80px;
  text-align: center;
`

const Paragraph = styled.p`
  font-size: 1.8rem;
  margin-bottom: 80px;
`

const CoursePrice = styled.p`
  font-size: 2.1rem;
`

const PaymentPage = ({ location: { pathname } }) => {
  // will be able to select course in the future
  const courseName = '12回コース'
  const totalPriceString = '132,000円'
  return (
    <Layout>
      <SEO
        title="お申し込みページ　| ITエンジニア専用のオンライン英会話スクール・Moba Pro IT English（モバプロ）"
        description="コースのお申し込み用のページです。"
        pathName={pathname}
      />
      <SectionHeading withoutAnimation>
        お申し込みページ
      </SectionHeading>
      <Section>
        <InnerStyledGray>
          <p>こちらのページから12回コースのお申し込みをしていただくことが出来ます。</p>
          <p>
            お申込みの必要事項をご記入いただいたあと、クレジットカードでの支払いページでコース料金をお支払い下さい。
          </p>
        </InnerStyledGray>
      </Section>
      <Section>
        <InnerMedium>
          <FormWrapper>
            <h2>{`コース名: ${courseName}`}</h2>
            <CoursePrice>{`お支払い料金総額（税込）: ${totalPriceString}`}</CoursePrice>
            <Paragraph>お支払い方法: クレジットカードによる一括払い</Paragraph>
            <Form
              type="payment-course-12-lessons"
              note={`コース名: ${courseName}, お支払い料金総額（税込）: ${totalPriceString}`}
            />
          </FormWrapper>
        </InnerMedium>
      </Section>
    </Layout>
  )
}

PaymentPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}

PaymentPage.defaultProps = {
  location: null,
}

export default PaymentPage
